import React, {useContext, useState, useEffect} from 'react'
import { GlobalState } from '../../GlobalState'
import handleIncreaseQuality from '../utils/HandleIncrease'

export default function Background({data}) {
  const state = useContext(GlobalState)
  const [utils] = state.utilsAPI.utils
  const [isMobile] = state.responsive.isMobile

  const [noImage, setnoImage] = useState('')
  const [image_new, setImage_new] = useState(null);

  useEffect(()=>{
    const getBackground = ()=>{
      utils.forEach(el=>{
        if(el.id===1){
          setnoImage(el.background)
        }
      })
    }
    getBackground()
  },[utils])

  useEffect(()=>{
    if(noImage){
        handle_increase()
    }
  },[noImage])

  const handle_increase = async ()=>{
    try {
        const newImage = await handleIncreaseQuality(noImage)
        setImage_new(newImage)
        
    } catch (err) {
        console.error('Error:', err)
    }
}

  // console.log(data)
  return (
    <div className={data === 'admin' ? 'hidden':` ${isMobile?'mr-[-10px]':''} w-full h-auto mt-[70px] mx-auto`}>
        <img src={image_new} alt='Ảnh bìa'
            className='w-full h-full object-cover mx-auto' />
    </div>
  )
}
