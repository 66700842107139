import React, {useState, useEffect, useContext, useRef} from 'react'
import { GlobalState } from '../../../../GlobalState'
import { Link } from 'react-router-dom'
import { FaAngleDown, FaAngleRight, FaAngleLeft, FaLeaf } from "react-icons/fa6";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import hinhtam from '../../../../img/tam1.jfif'

import handleIncreaseQuality from '../../../utils/HandleIncrease';

const CSS_design = {
    li: 'bg-[#eee] border-b-[1px] border-b-[#ccc] py-[10px] px-[15px] font-bold cursor-pointer text-[13px] flex justify-between items-center hover:bg-[#ED0678] hover:text-[#fff]',
    li_child: 'bg-[#eee] border-b-[1px] border-b-[#ccc] py-[10px] px-[25px] cursor-pointer text-[13px] flex justify-between items-center text-[#555] hover:bg-[#ED0678] hover:text-[#fff]',
    li_right: ' border-b-[1px] border-b-[#ccc] py-[15px] cursor-pointer text-[14px] flex justify-between items-center text-[#555] hover:text-[#ED0678]'
}

export default function Section1() {
    const state = useContext(GlobalState)
    const [category] = state.categoryHomePage.category
    const [news] = state.newsAPI.news
    const [cluster] = state.clusterAPI.cluster
    const [category_child1] = state.category_childAPI.category_child1
    const [isMobile] = state.responsive.isMobile
    const [dataNews, setDataNews] = useState([])

    const [showCategory, setShowCategory] = useState('')
    const [showCategory1, setShowCategory1] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef(null);

    const [listmenu, setListMenu] = useState([])
    const [checkShowMenuChild, setCheckShowMenuChild] = useState('')

    const [list_id_cate, setList_id_cate] = useState([])
    const [listmenu2, setListMenu2] = useState([])
    const [showCategory2, setShowCategory2] = useState('')
    const [list_id_baiviet, setList_id_baiviet] = useState([])
    const [list_id_baiviet_2, setList_id_baiviet_2] = useState([])
    const [list_id_baiviet_3, setList_id_baiviet_3] = useState([])

    useEffect(()=>{
        const getList = ()=>{
            let arr = []
            category.forEach(el=>{
                if(el.id_baiviet!==null){
                    arr.push(el.id_baiviet)
                }
            })
            const arrNew = [...new Set(arr)]
            setList_id_baiviet(arrNew)
        }
        getList()
    },[category])
    // console.log(list_id_baiviet)

    const handleShowCategory = (id)=>{
        setShowCategory(id)
        setShowCategory1(!showCategory1)
    }

    useEffect(()=>{
        const getNews = ()=>{
            let arr = []
            news.slice(0,10).forEach(el=>{
                arr.push(el)
            })
            setDataNews(arr)
        }
        getNews()
    },[news])

    // Cấu hình slider
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => setCurrentIndex(next),
        ref: sliderRef,
        autoplay: true, // Tự động trượt
        autoplaySpeed: 3000
    }

    const nextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    }

    const prevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    }

    const handle_increase = async (value)=>{
        try {
            const newImage = await handleIncreaseQuality(value)
            return newImage
            
        } catch (err) {
            console.error('Error:', err)
        }
    }

    const showMenuChild = (id)=>{
        setCheckShowMenuChild(id)
        setShowCategory(id)
        // setShowCategory1(!showCategory1)

        let arr = []
        category.forEach(el=>{
            if(el.id_cate===id){
                arr.push(el)
            }
        })
        setListMenu(arr)
    }

    useEffect(()=>{
        const handleCateChild2 = ()=>{
            let arr = [], arr2 = []
            cluster.forEach(el=>{
                arr.push(el.id_cate)

                if(el.id_baiviet!==null){
                    arr2.push(el.id_baiviet)
                }
            })

            let arrNew = [...new Set(arr)];
            let arrNew2 = [...new Set(arr2)];

            setList_id_cate(arrNew)
            setList_id_baiviet_2(arrNew2)
            // console.log(arrNew)
        }
        handleCateChild2()
    },[cluster])

    useEffect(()=>{
        const handle_baiviet = ()=>{
            let arr = []
            category_child1.forEach(el=>{
                if(el.id_baiviet!==null){
                    arr.push(el.id_baiviet)
                }
            })
            let arrNew = [...new Set(arr)];
            setList_id_baiviet_3(arrNew)
        }
        handle_baiviet()
    },[category_child1])

    const handle_danhmuc_cap2 = (id)=>{
        setShowCategory2(id)

        let arr = []
        cluster.forEach(el=>{
            if(el.id_cate===id){
                arr.push(el)
            }
        })
        setListMenu2(arr)
    }

    // console.log(list_id_baiviet_2)

  return (
    <div className={`${isMobile?'flex-wrap mx-[10px] mr-[-20px]':''} w-full h-auto flex justify-between`}>
        <div className={`${isMobile?'w-full mb-[10px]':'w-[25%]'} h-auto bg-[#eee]`}>
            <ul className='relative'>
                <li className='border-b-[1px] border-b-[#ccc] py-[10px] px-[15px] font-bold cursor-pointer text-[13px] 
                    text-center text-white bg-[#ED0678]'>DANH MỤC</li>
                {
                    category_child1.map(cate=>{
                        if(cate.id!==0){
                            if(cate.check_con===0){
                                return <div key={cate.id} onClick={()=>showMenuChild(cate.id)}>
                                    <li className={CSS_design.li}>{cate.name}</li>
                                    {/* <li className={CSS_design.li}><p>{cate.name}</p> <p>{showCategory===cate.id && showCategory1 ? <FaAngleDown/> : <FaAngleRight/>}</p></li> */}
                                    {
                                        showCategory === cate.id ?
                                        listmenu.map(l=>{
                                            // if(list_id_cate.includes(l.id)){
                                            if(l.check_con===0){
                                                return <>
                                                    <div key={l.id} className={checkShowMenuChild===cate.id?'bg-[#f6f6f6] border-b-[1px] border-[#ddd] text-[#555] block px-[25px] py-[7px] text-[14px] cursor-pointer hover:bg-[#f955a7]':'hidden'}>
                                                        <li className={``} onClick={()=>handle_danhmuc_cap2(l.id)}>
                                                            {l.name}
                                                        </li>
                                                    </div>
                                                    {
                                                        showCategory2 === l.id ?
                                                        listmenu2.map(lc=>{
                                                            if(!list_id_baiviet_2.includes(lc.id_baiviet)){
                                                                return <Link to={`/danh-muc/${lc.link}/${lc.id}`} key={lc.id} className={'bg-[#f9f9f9] border-b-[1px] border-[#ddd] text-[#e74295] block w-full px-[45px] py-[7px] text-[14px] cursor-pointer hover:bg-[#f955a7] hover:text-[#fff]'}>{lc.name}</Link>
                                                            }

                                                            if(list_id_baiviet_2.includes(lc.id_baiviet)){
                                                                return <Link to={`/chi-tiet-bai-viet/${lc.id_baiviet}`} key={lc.id} className={'bg-[#f9f9f9] border-b-[1px] border-[#ddd] text-[#e74295] block w-full px-[45px] py-[7px] text-[14px] cursor-pointer hover:bg-[#f955a7] hover:text-[#fff]'}>{lc.name}</Link>
                                                            }
                                                        })
                                                        : <></>
                                                    }
                                                </>
                                            }

                                            // if(!list_id_cate.includes(l.id)){
                                            if(l.check_con===1){
                                                if(!list_id_baiviet.includes(l.id_baiviet)){
                                                    return <Link to={`/danh-muc/${l.link}/${l.id}`} key={l.id} className={checkShowMenuChild===cate.id?'bg-[#f6f6f6] border-b-[1px] border-[#ddd] text-[#555] block px-[25px] py-[7px] text-[14px] cursor-pointer hover:bg-[#f955a7]':'hidden'}>
                                                        <li className={``}>
                                                            {l.name}
                                                        </li>
                                                    </Link>
                                                }

                                                if(list_id_baiviet.includes(l.id_baiviet)){
                                                    return <Link to={`/chi-tiet-bai-viet/${l.id_baiviet}`} key={l.id} className={checkShowMenuChild===cate.id?'bg-[#f6f6f6] border-b-[1px] border-[#ddd] text-[#555] block px-[25px] py-[7px] text-[14px] cursor-pointer hover:bg-[#f955a7]':'hidden'}>
                                                        <li className={``}>
                                                            {l.name}
                                                        </li>
                                                    </Link>
                                                }
                                            }
                                        })
                                        :<></>
                                    }
                                </div>
                            }

                            if(cate.check_con===1){
                                if(!list_id_baiviet_3.includes(cate.id_baiviet)){
                                    return <Link to={`/danh-muc/${cate.link}/${cate.id}`}>
                                        <li className={CSS_design.li}>{cate.name}</li>
                                    </Link>
                                }

                                if(list_id_baiviet_3.includes(cate.id_baiviet)){
                                    return <Link to={`/chi-tiet-bai-viet/${cate.id_baiviet}`}>
                                        <li className={CSS_design.li}>{cate.name}</li>
                                    </Link>
                                }
                            }
                        }
                    })
                }

                <li className={CSS_design.li} onClick={handleShowCategory}><p>Danh mục khác</p></li>
                {
                    category.map(cate=>{
                        if(cate.id_cate===0){
                            if(cate.check_con===1){
                                if(cate.id!==0){
                                    return <Link to={`/danh-muc/${cate.link}/${cate.id}`} key={cate.id} className={showCategory1 ? '' : 'hidden'}>
                                        <li className={CSS_design.li_child}>{cate.name}</li>
                                    </Link>
                                }
                            }

                            if(cate.check_con===0){
                                if(cate.id!==0){
                                    return <div key={cate.id} className={showCategory1 ? '' : 'hidden'}>
                                        <li className={CSS_design.li_child}>{cate.name}</li>
                                    </div>
                                }
                            }
                        }
                    })
                }

                {/* {
                    category.map(cate=>{
                        if(cate.id_cate===0){
                            return <Link to={`/danh-muc/${cate.link}/${cate.id}`} key={cate.id}>
                                <li className={CSS_design.li}>{cate.name}</li>
                            </Link>
                        }
                    })
                } */}

                {/* {
                    cluster.map(clu=>{
                        return <Link to={`/danh-muc/cum/${clu.id}`} key={clu.id}><li className={CSS_design.li}><p>{clu.name}</p></li></Link>
                    })
                } */}
                {/* <li className={CSS_design.li} onClick={handleShowCategory}><p>Danh mục khác</p> <p>{showCategory1 ? <FaAngleDown/> : <FaAngleRight/>}</p></li> */}
            </ul>
        </div>

        {/* Tạm thời */}
        {/* <div className={`${isMobile?'w-full mb-[10px]':'w-[50%]'} h-full relative px-[10px] overflow-hidden`}>
            <Slider {...settings} className="flex w-full">
                {dataNews.map((slide, index) => (
                    <Link to={`/chi-tiet-bai-viet/${slide.id}`} key={index} className={`slide w-full h-[300px] transform transition-transform ${currentIndex === index ? 'translate-x-0' : 'translate-x-0'}`}>
                        <div>
                            <img src={slide.images} alt='Ảnh đại diện' className='w-full h-[300px] object-cover' />
                            <p className='my-[10px] font-bold text-lg text-justify'>{slide.title}</p>
                            <p className='text-sm text-gray-600 text-justify'>{slide.description.slice(0,400)}....</p>
                        </div>
                    </Link>
                ))}
            </Slider>

            <button className={`${isMobile?'top-1/4':'top-1/3'} absolute transform -translate-y-1/2 left-[10px] text-white px-2 py-1 bg-transparent`} onClick={prevSlide}><FaAngleLeft size={'30px'}/></button>
            <button className={`${isMobile?'top-1/4':'top-1/3'} absolute transform -translate-y-1/2 right-[10px] text-white px-2 py-1 bg-transparent`} onClick={nextSlide}><FaAngleRight size={'30px'}/></button>
        </div> */}

        <div className={`${isMobile?'':'w-[530px] h-[300px]'} px-[10px]`}>
            <Link to={`/chi-tiet-bai-viet/${213}`}>
                <img src={hinhtam} alt='Ảnh đại diện' className='w-full h-[300px] object-cover' />
                <p className={`${isMobile?'text-[18px]':'text-[23px]'} my-[10px] font-bold text-center pt-[13px]`}>THƯ NGỎ : CHUNG TAY HƯỞNG ỨNG <br/> CHƯƠNG TRÌNH XOÁ NHÀ TẠM , <br/> NHÀ DỘT NÁT</p>
                <p className='text-sm text-gray-600 text-justify'></p>
            </Link>
        </div>

        <div className={`${isMobile?'w-full h-[200px] mb-[10px]':'w-[25%] h-[450px]'} px-[10px] overflow-y-scroll`}>
            <ul>
                {
                    dataNews.map(n=>{
                        return <Link to={`/chi-tiet-bai-viet/${n.id}`} key={n.id}><li className={CSS_design.li_right}>{n.title}</li></Link>
                    })
                }
            </ul>
        </div>
    </div>
  )
}
